// Custom hook to fetch teams based on companyId
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getTeamsByCompanyId } from "src/react-query/endPoints";
import Token from "src/util/Token";

const fetchTeams = async (companyId) => {
  const token = Token.get("_flst");
  if (!token) {
    throw new Error("No token found");
  }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(getTeamsByCompanyId(companyId), config);
  return response.data;
};

export const useTeams = (companyId) => {
  return useQuery(["teams", companyId], () => fetchTeams(companyId), {
    enabled: !!companyId,
  });
};
