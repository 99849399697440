import { useMatch, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Icon,
} from "@mui/material";
import Countries from "src/data/countries.json";

import { Divider } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { Form, FormikProvider, useFormik } from "formik";

import {
  acceptableCurrencyList,
  getCompanyDefaultValues,
} from "./company.constants";
import { companyFormValidationSchema } from "src/util/validationSchemas";
import {
  availablePaymentGatewayListUrl,
  companyUrl,
  getCompanyUrlId,
} from "src/react-query/endPoints";
import {
  useGetDetail,
  useGetPaymentGatewayDetails,
  useGetPaymentGatewayList,
  useList,
  useMutate,
} from "src/hooks/useFetch.hook";
import { useCallback, useEffect, useRef, useState } from "react";
import { LoadingButton, MobileDatePicker } from "@mui/lab";
import { Loader } from "../Loader";
import { is } from "src/util/is";
import Address from "src/common/components/Address";
import { alertSlice } from "src/store/alert/alert.slice";
import { useDispatch } from "react-redux";
import { useDomainConfig } from "src/hooks/useDomainConfig";
import { useMutation } from "@tanstack/react-query";
import { iconUpload } from "src/components/ServiceRequest/serviceRequest.hook";
import { UploadImage } from "./uploadImage";

export const CompanyForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const domainConfig = useDomainConfig();
  const { companyId } = useParams();
  const { mutate, isLoading } = useMutate("companyList");
  const {
    mutate: availablePaymentGatewayList,
    isLoading: isFetchingAvailablePaymentGatewayList,
  } = useGetPaymentGatewayList();
  const isCreateCompany = useMatch("/company/new");
  const { data: company, isFetching } = useGetDetail("company", {
    id: companyId,
  });
  const iconList = [
    {
      iconTitle: "Grey Icon",
      iconKey: "greyIcon",
    },
    {
      iconTitle: "Green  Icon",
      iconKey: "greenIcon",
    },
    {
      iconTitle: "Red Icon",
      iconKey: "redIcon",
    },
    {
      iconTitle: "Icon",
      iconKey: "icon",
    },
  ];
  // const [savedPaymentGatewayId,setSavedPaymentGatewayId] = useState(null);
  // const { data: paymentGatewayDetails, isFetching:isFetchingPaymentGatwayDetails } = useGetPaymentGatewayDetails(isCreateCompany,savedPaymentGatewayId)
  const [gatewayList, setGatewayList] = useState([{ id: 0, name: "none" }]);
  const onSubmit = async (companyData, { setSubmitting }) => {
    const {
      street,
      houseNumber,
      zipCode,
      city,
      state,
      country,
      locationLatitude,
      locationLongitude,
      addressId,
      paymentGatewayDetailsId,
      taxPercent,
      transactionPercent,
    } = companyData;
    const {
      actions: { setAlert },
    } = alertSlice;

    //IMPORTANT VALIDATIONS

    // const zeroValue=(val)=>parseFloat(val)===0
    // if(zeroValue(transactionPercent) || zeroValue(taxPercent)){
    //   let errorsUpd = {...errors,transactionPercent:zeroValue(transactionPercent)?"Specify a value greater than zero":"",taxPercent:zeroValue(taxPercent)?"Specify a value greater than zero":""}
    //   setErrors(errorsUpd);
    //   dispatch(
    //     setAlert({
    //       open: true,
    //       message: `Specify a value greater than zero for the percentages`,
    //       severity: "error",
    //     })
    //   );
    //   setSubmitting(false);
    //   return
    // }

    if (paymentGatewayDetailsId === 0) {
      let errorsUpd = {
        ...errors,
        paymentGatewayDetailsId: "Please select a payment gateway",
      };
      setErrors(errorsUpd);
      dispatch(
        setAlert({
          open: true,
          message: "Please select a payment gateway",
          severity: "error",
        })
      );
      setSubmitting(false);
      return;
    }
    const provider = await UploadProviderImagesToAWS();
    provider.name = companyData.name;
    companyData.provider = provider;
    setFieldValue("provider", provider);

    companyData.address = {
      street,
      houseNumber,
      zipCode,
      city,
      state,
      country,
      lat: locationLatitude,
      lng: locationLongitude,
    };

    const mutateObject = {
      url: companyUrl,
      method: "POST",
    };

    if (!isCreateCompany && !is.nullOrUndefined(companyId)) {
      mutateObject.method = "PUT";
      mutateObject.url = getCompanyUrlId(companyId);
      companyData.address.addressId = addressId;
      delete companyData.addressId;
    }
    companyData.domain = domainConfig.domain;

    mutate(
      {
        url: mutateObject.url,
        method: mutateObject.method,
        body: { company: companyData },
      },
      { onSuccess }
    );
    setSubmitting(false);
  };

  const onSuccess = () => navigate("/company");

  const formik = useFormik({
    initialValues: getCompanyDefaultValues(),
    validationSchema: companyFormValidationSchema,
    onSubmit,
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
    setFieldValue,
    setErrors,
  } = formik;

  const UploadProviderImagesToAWS = async () => {
    return new Promise(async (res) => {
      const provider = values["provider"];

      for await (let data of iconList) {
        const icon = provider[data?.iconKey];
        if (icon && !icon.startsWith("http")) {
          const response = await iconUpload({ image: provider[data?.iconKey] });
          provider[data.iconKey] = response.data.data;
        }
      }
      res(provider);
    });
  };

  useEffect(() => {
    if (company) {
      let {
        address,
        paymentGatewayDetailsId,
        paymentCurrency,
        payoutAccounts,
        provider,
        ...rest
      } = company;

      const { zipcode, lat, lng } = address;
      let finalPayoutAccountsArr = [
        {
          business_name: "",
          business_type: "",
          beneficiary_name: "",
          email: "",
          phone: "",
          account_no: "",
          ifsc_code: "",
          settlement_percentage: "",
          disabled: false,
        },
      ];
      if (!is.empty(payoutAccounts)) {
        finalPayoutAccountsArr = [...payoutAccounts];
      }
      const companyValues = {
        ...rest,
        ...address,
        zipCode: zipcode,
        locationLatitude: lat,
        locationLongitude: lng,
        payoutAccounts: finalPayoutAccountsArr,
        paymentGatewayDetailsId,
        paymentCurrency,
        provider,
      };
      console.log(`companyValues:`, companyValues);
      resetForm({ values: companyValues });
    }
  }, [company, resetForm]);

  useEffect(() => {
    const mutateObject = {
      url: availablePaymentGatewayListUrl,
      method: "GET",
    };
    availablePaymentGatewayList(mutateObject, {
      onSuccess: (data) => {
        const { data: list } = data;
        if (!is.empty(list)) {
          setGatewayList(list);
        }
      },
    });
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (!isCreateCompany && is.empty(company)) {
    navigate("/company");
    return null;
  }
  return (
    <Card sx={{ p: 2 }}>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          id="company"
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <TextField
                  required
                  id="outlined-basic"
                  label="Company Name"
                  size="small"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Subscription Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Subscription Type"
                  {...getFieldProps("subscriptionId")}
                >
                  <MenuItem value={1}>Basic</MenuItem>
                  <MenuItem value={2}>Chargemiles</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="payment-type">Payment Type</InputLabel>
                <Select
                  labelId="payment-type"
                  id="demo-simple-select"
                  label="Payment Type"
                  {...getFieldProps("paymentConfirmationNo")}
                >
                  <MenuItem value="card">Card</MenuItem>
                  <MenuItem value="upi">UPI</MenuItem>
                  <MenuItem value="cash">Cash</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                size="small"
                error={Boolean(
                  touched.paymentCurrency && errors.paymentCurrency
                )}
              >
                <InputLabel id="demo-simple-select-label">
                  Payment Currency Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Payment Currency Typee"
                  {...getFieldProps("paymentCurrency")}
                  value={values["paymentCurrency"]}
                >
                  {acceptableCurrencyList.map(({ value, title }, index) => (
                    <MenuItem
                      value={value}
                      key={`${value}-${index}-currency-${title}`}
                    >
                      {title}
                    </MenuItem>
                  ))}
                </Select>
                {touched.paymentCurrency && (
                  <FormHelperText>{errors.paymentCurrency}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Payment Gateway
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Payment Gateway"
                  // {...getFieldProps("paymentGatewayDetailsId")}
                  value={values["paymentGatewayDetailsId"]}
                  onChange={(event) => {
                    const selectedGateway = gatewayList.find(
                      (gateway) => gateway.id === event.target.value
                    );
                    setFieldValue(
                      "paymentGatewayDetailsId",
                      selectedGateway.id
                    );
                    setFieldValue(
                      "paymentGatewayId",
                      selectedGateway.payment_gateway_id
                    );
                  }}
                >
                  {gatewayList.map((value, index) => (
                    <MenuItem
                      value={value?.id}
                      key={`${value?.id}-${index}-gateway-${value?.name}`}
                    >
                      {value?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <TextField
                  required
                  id="outlined-basic"
                  label="Tax Percentage"
                  size="small"
                  {...getFieldProps("taxPercent")}
                  error={Boolean(touched.taxPercent && errors.taxPercent)}
                  helperText={touched.taxPercent && errors.taxPercent}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <TextField
                  required
                  id="outlined-basic"
                  label="Transaction Percentage"
                  size="small"
                  {...getFieldProps("transactionPercent")}
                  error={Boolean(
                    touched.transactionPercent && errors.transactionPercent
                  )}
                  helperText={
                    touched.transactionPercent && errors.transactionPercent
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Address
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
            formValues={values}
            hide={true}
            displayedFor="company"
          />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Typography variant="subtitle1" className="mb-2">
                Company Info
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Root User"
                  {...getFieldProps("rootUser")}
                  disabled={!isCreateCompany}
                  error={Boolean(touched.rootUser && errors.rootUser)}
                  helperText={touched.rootUser && errors.rootUser}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  id="outlined-basic"
                  label="Email Address"
                  size="small"
                  disabled={!isCreateCompany}
                  {...getFieldProps("emailId")}
                  error={Boolean(touched.emailId && errors.emailId)}
                  helperText={touched.emailId && errors.emailId}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <Typography variant="subtitle1" className="mb-2">
                Add Icons
              </Typography>
              <Divider sx={{ marginBottom: "8px" }} />
            </Grid>
            {iconList.map((data) => (
              <Grid item xs={12} lg={3}>
                <UploadImage
                  getFieldProps={getFieldProps}
                  setFieldValue={setFieldValue}
                  formValues={values}
                  iconKey={data.iconKey}
                  iconTitle={data.iconTitle}
                  iconUrl={data.iconUrl}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Typography variant="subtitle1" className="mb-2">
                Comments
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth size="small">
                <TextField
                  sx={{ m: 1, width: 500 }}
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  {...getFieldProps("comments")}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={6} lg={6}>
              <Stack direction="row" spacing={2}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting || isLoading}
                >
                  Save
                </LoadingButton>
                <LoadingButton
                  size="large"
                  type="button"
                  variant="outlined"
                  component={RouterLink}
                  to="/company"
                >
                  Cancel
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Card>
  );
};
